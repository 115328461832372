<div [hidden]="hideSpinner" class="loading-screen">
<div class="loading-item-container">
<img class="loading-logo" src="">
<div class="loader"></div>
</div>
</div>
<div class="master-container">
<router-outlet></router-outlet>
<!-- /%app-privacy -->
</div>
