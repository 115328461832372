<div [ngClass]="{&quot;disabled&quot;: video.length &lt; 1}" class="hotspot">
<!-- .bubble{'[ngClass]' => '{"left": left == true}'} -->
<!-- .circle{'[ngStyle]' => '{"background-color": hotspotService.getFilterColour(filter)}', '[ngClass]' => '{"left-circle": left == true}'} -->
<!-- %span {{pinNumber}} -->
<!-- %i.fas.fa-map{'[hidden]' => '!map'} -->
<!-- %i.fas.fa-info{'[hidden]' => 'map'} -->
<!-- %span {{pinTitle}} -->
<img src="{{hotspotImage}}">
<h2>{{pinTitle}}</h2>
</div>
