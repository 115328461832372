import { ComponentFactoryResolver, Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';


declare var $:any;
var SpriteSpin = require("spritespin");


@Injectable({
  providedIn: 'root'
})
export class DrilldownService {

  maps = null;

  boxData = null;
  boxDataDisplay = 'intro';
  drilldownOpen = false;

  boxDisplay = false;

  boxDataTitle          = "";
  boxDataDescription    = "";
  boxDataPDF            = [];
  boxDataInterest       = null;
  boxDataImage          = null;
  boxDataVideo          = null;
  boxDataVideoURL       = "";
  boxDataSubtitle       = "";
  boxDataGallery        = [];
  boxDataBigImage       = null;
  boxDataSelectedPDF: SafeResourceUrl    = null;
  boxDataOverview       = null;
  boxDataOverviewImage  = null;
  boxDataBackgroundVideo = null;
  boxDataBackdrop       = null;

  boxDataOverviewTitle  = null;
  boxDataOverviewDescription  = null;
  boxDataOverviewLink   = null;

  contactOpen           = false;
  boxDataExternal       = "";

  t60BoxLoaderDisplay   = true;
  boxData360Images      = [];
  boxData360            = null;
  boxData360Url         = null;

  boxDataExternalLinks  = null;

  mapBg: string         = null;
  mapVideoBg: string    = null;

  currentSlideshowIndex = 0;

  hotspots              = [];

  currentPdfUrl         = null;

  apiURL                = this.apiService.getAPIURL();

  mapId: string = "1";

  logoImage: string = "";

  procedure = {time:  null, step: null, description: null, id: null, stepId: null};


  constructor(private apiService: ApiService,
    private sanitiser: DomSanitizer,
    private router: Router) { }

  async getDrillDownData(route: string){
    let hotspot = await this.apiService.getDrilldownRoute(route, this.mapId).then((res: any) => {
      this.maps = res;
      if(res[0]['logoImage']){
        this.logoImage = this.apiURL + res[0]['logoImage']['url'];
      }
      let hotpsots = [];

      if(this.mapId == ""){
        if(route == "contact"){
          this.setMapInfo();
          this.setDrillDownData("contact");
        }else{
          res.forEach(element => {
            if(element['hotspot'] && element['hotspot'].length > 0){
              for(let hotspot in element['hotspot']){
                hotpsots.push(element['hotspot'][hotspot]);
              }
            }
          })

          let found = hotpsots.filter(x => x['route'] == route);
          found.forEach(item => {
            if(item['externalURL'] != "globalHotspot"){
              this.setDrillDownData(item);
            }
          })
        }
      }else{
        if(route == "contact"){
          this.setMapInfo();
          this.setDrillDownData("contact");
        }else{
          this.apiService.getGlobalDrilldowns().then((result: any) => {
            result.forEach(element => {
              hotpsots.push(element['hotspot']);
            });
            //For the logo of the drilldown
            this.setDrillDownData(hotpsots.find(x => x['route'] == route));
          });
        }
      }
    });
  }

  setMapInfo(){
    let map = this.maps.find(x => x.mapTitle == "main");
    if(map['mapBackgroundImage']){
      this.setMapBg(map['mapBackgroundImage']['url']);
    }
    this.setMapVideoBg(map['mapVideoURL']);
    /*
    if(this.mapId != "main"){
      map = this.maps.find( x => x.mapTitle == this.mapId);
      if(map){
        this.mapId = map.title
      }else{
        this.mapId = "main";
      }
    }
    */

 //   this.setBoxDataInterest(this.maps.find(x => x.id == 1)['interest']);
  }

  setDrillDownData(boxData){
    if(boxData == "contact"){
      this.setBoxDataDisplay("contact");
      this.setBoxDataImage(null);
      this.setBoxDataBackdrop(null);
      this.boxData = null;
      this.setBoxData360Url(null);
      this.setBoxDataGallery([]);
      this.setBoxDataSelectedPDF("https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf");
    }else if(!boxData){
      this.router.navigate(['/error']);
    }else{
      this.setBoxData(boxData);
      console.log(boxData);
      if(this.boxData['defaultTab'] && this.tabMatch(this.boxData['defaultTab'])){
        this.setBoxDataDisplay(this.boxData['defaultTab']);
      }else{
        this.setBoxDataDisplay('intro');
      }
      this.setBoxDataExternal(this.boxData['externalURL']);
      this.setboxDataTitle(this.boxData['title']);
      this.setboxDataPDF(this.boxData['PDF']);
      this.setboxDataVideo(this.boxData['video']);
      this.setDataSubtitle(this.boxData['subtitle']);
      this.setboxDataDescription(this.boxData['description']);
      this.setBoxData360(this.boxData['Spin']);
      this.setBoxDataBackgroundVideo(this.boxData['assetVideo']['videoURL'] + '?background=1');
      this.setBoxDataBackdrop(this.apiURL + this.boxData['assetVideo']['backdrop']['url']);

      if(this.boxData['images'].length > 0){
        this.setBoxDataGallery(this.boxData['images']);
        this.setBoxDataBigImage(this.boxData['images'][0]['images']['url']);
      }else{
        this.setBoxDataGallery([]);
      }


      //Reset empty PDF array
      if(this.boxDataPDF.length == 0) {
        this.boxDataPDF = null;
      }else{
        if(!this.isMobile()){
          this.setBoxDataSelectedPDF(this.boxDataPDF[0]['URL']);
        }
      }

      if(this.boxDataVideo.length > 0){
        this.setBoxDataVideoUrl(this.boxDataVideo[0]['videoURL']);
      }

      if(this.boxData['assetImage'].length > 0) {
        this.setBoxDataImage(this.apiURL + this.boxData['assetImage'][0]['images']['url']);
      } else {
        this.setBoxDataImage(null);
      }

      if(this.boxData['mapId']){
        this.setMapId(boxData['mapId']);
      }

      if(this.boxData['overview']){
        this.boxDataOverview = this.boxData['overview'];
      }

      this.boxDataExternalLinks = this.boxData['externalLinks'];

      this.setMapInfo();
    }
  }

  isMobile(){
    try{ document.createEvent("TouchEvent"); return true; }
    catch(e){ return false; }
  }

  tabMatch(defaultTab: string): Boolean{
    if(defaultTab == "gallery" || defaultTab == "video" || defaultTab == "document" || defaultTab == "intro"){
      return true;
    }else{
      return false;
    }
  }

  setBoxDataBackgroundVideo(src){
    this.boxDataBackgroundVideo = src;
  }

  getBoxDataBackgroundVideo(){
    return this.boxDataBackgroundVideo;
  }

  emptyBoxDataBackgroundVideo() {
    this.boxDataBackgroundVideo = null;
  }

  setCurrentProcedure(procedure){
    this.procedure = procedure;
  }

  getCurrentProcedure(){
    return this.procedure;
  }

  setBoxDisplay(boxDisplay){
    this.boxDisplay = boxDisplay;
  }

  getBoxDisplay(){
    return this.boxDisplay;
  }

  setHotspots(hotspots){
    this.hotspots = hotspots;
  }

  getHotspots(){
    return this.hotspots;
  }


  getBoxData(){
    return this.boxData;
  }

  setBoxData(boxData){
    this.boxData = boxData;
  }

  getDrilldownOpen(): boolean{
    return this.drilldownOpen;
  }

  setBoxDataDisplay(sectionIn:string): void{
    this.boxDataDisplay = sectionIn;
  }

  getBoxDataDisplay(){
    return this.boxDataDisplay;
  }

  getContactOpen(): boolean{
    return this.contactOpen;
  }

  getBoxDataTitle(){
    return this.boxDataTitle;
  }

  getBoxDataDescription(){
    return this.boxDataDescription;
  }

  getBoxDataPDF(){
    return this.boxDataPDF;
  }

  getBoxDataInterest(){
    return this.boxDataInterest;
  }

  getBoxDataVideo(){
    return this.boxDataVideo;
  }

  getBoxDataSubtitle(){
    return this.boxDataSubtitle;
  }

  setboxDataTitle(title){
    this.boxDataTitle = title;
    console.log(this.boxDataTitle)
  }

  setboxDataDescription(description){
    this.boxDataDescription = description;
  }

  setboxDataPDF(pdf){
    this.boxDataPDF = pdf;
  }

  setboxDataInterest(interest){
    this.boxDataInterest = interest;
  }

  setboxDataVideo(video){
    this.boxDataVideo = video;
  }

  setDataSubtitle(subtitle){
    this.boxDataSubtitle = subtitle;
  }

  getBoxDataImage(){
    return this.boxDataImage;
  }

  getBoxDataBackdrop() {
    return this.boxDataBackdrop;
  }

  setBoxDataImage(image){
    this.boxDataImage = image;
  }

  setBoxDataBackdrop(image){
    this.boxDataBackdrop = image;
  }

  getBoxDataVideoUrl(){
    return this.boxDataVideoURL;
  }

  setBoxDataVideoUrl(videoURL){
    this.boxDataVideoURL = videoURL;
  }

  setBoxDataInterest(interest){
    this.boxDataInterest = interest;
  }

  getBoxDataGallery(){
    return this.boxDataGallery;
  }

  setBoxDataGallery(boxDataGallery){
    this.boxDataGallery =  boxDataGallery;
  }

  getBoxDataBigImage(){
    return this.boxDataBigImage
  }

  setBoxDataBigImage(boxDataBigImage){
    this.boxDataBigImage = this.apiURL + boxDataBigImage;
  }

  getBoxDataSelectedPDF(): SafeResourceUrl{
    return this.boxDataSelectedPDF;
  }

  getCurrentPdfUrl(){
    return this.currentPdfUrl;
  }

  setBoxDataSelectedPDF(boxDataSelectedPDF){
    this.currentPdfUrl = boxDataSelectedPDF;
    let url = boxDataSelectedPDF;
    this.boxDataSelectedPDF = this.sanitiser.bypassSecurityTrustResourceUrl(url);
  }

  setSlideShow(){
    var self = this;
    $("#mySpriteSpin").spritespin({
      // path to the source images.
      source: self.boxData360Images,
      width: 854,
      height: 480,
      animate: false,
      responsive: true,

      plugins: [
        'progress',
        '360',
        'drag'
      ],
      onComplete:function(){
        self.t60BoxLoaderDisplay = false;
      }
    });
  }

  setBoxData360Images(images){
    this.boxData360Images = [];
    images.forEach(element => {
      this.boxData360Images.push(this.apiService.getAPIURL() +  element['url']);
    });
  }

  getBoxData360Images(){
    return this.boxData360Images;
  }

  sort360Images(images: Array<string>){
    let n = images.length;
    //sort images
    for(let i = 0; i < n-1; i++){
      for(let j = 0; j < n-i-1; j++){
        //get the image number
        let J: Number = this.splitImageName(images[j]['name']);
        let jPlus: Number = this.splitImageName(images[j+1]['name']);
        if(J > jPlus){
          let temp = images[j];
          images[j] = images[j+1];
          images[j+1] = temp;
        }
      }
    }
    //set the images
    this.setBoxData360Images(images);
  }

  splitImageName(name: string): Number{
    let temp = name.split('.')[0];
    let returnNum = Number(temp.substr(temp.length - 2));
    return returnNum;
  }

  setBoxData360Url(url){
    this.boxData360Url = url;
  }

  getBoxData360(){
    return this.boxData360;
  }

  setBoxData360(box360){
    this.boxData360 = box360;
  }

  nextImage(direction: number){
    this.currentSlideshowIndex += direction;
    if(this.currentSlideshowIndex < 0){
      this.currentSlideshowIndex = this.getBoxDataGallery().length - 1;
    }else if(this.currentSlideshowIndex > (this.getBoxDataGallery().length - 1)){
      this.currentSlideshowIndex = 0;
    }
    this.setBoxDataBigImage(this.boxDataGallery[this.currentSlideshowIndex]['images']['url']);
  }

  getMapId(){
    return this.mapId;
  }

  setMapId(id){
    this.mapId = id;
  }

  getLogoImage(){
    return this.logoImage;
  }

  getBoxDataExternal(){
    return this.boxDataExternal;
  }

  setBoxDataExternal(link){
    this.boxDataExternal = link;
  }

  getMapBg(){
    return this.mapBg;
  }

  setMapBg(mapBg: string){
    console.log(mapBg);
    this.mapBg = this.apiURL + mapBg;
  }

  getMapVideoBg(){
    return this.mapVideoBg;
  }

  setMapVideoBg(mapVideoBg: string){
    this.mapVideoBg = mapVideoBg +'?background=1';
    console.log(mapVideoBg);
  }
}

