import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'appFilter'
})
export class FilterPipe implements PipeTransform {

  transform(value: any, searchText: string): any {
    if(searchText){
      return value.filter(val => val['title'].toLowerCase().includes(searchText.toLowerCase()));
    }else{
      return value;
    }
  }
}
