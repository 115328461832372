import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HotspotService {

  filters = [];

  constructor() { }

  getFilterColour(filterName){
    if(filterName && filterName != "map"){
      let filter = this.filters.find(x => x['filterName'] == filterName);
      return filter['filterColour'];
    }else{
      return "#ACC007";
    }
  }

  setFilters(filters){
    this.filters = filters;
  }
}
