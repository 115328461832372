import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../api.service';
import { HotspotService } from '../hotspot.service';

@Component({
  selector: 'app-hotspot',
  templateUrl: './hotspot.component.html',
  styleUrls: ['./hotspot.component.scss']
})
export class HotspotComponent implements OnInit {
  constructor(private ApiService: ApiService, public hotspotService: HotspotService) { }
  @Input() dataIn: any;

  pinTitle:string    = null;
  pinNumber:string   = null;
  filter: string     = null;
  map: boolean       = false;
  left: boolean      = false;
  hotspotImage: string = null;
  video: any = null;

  ngOnInit(): void {
    this.initPin();
  }

  initPin(): void {
    if(this.dataIn['pinTitle']){
      this.pinTitle = this.dataIn['pinTitle'];
      this.pinNumber = this.dataIn['hotspotAlias'];
    }else{
      this.pinTitle = this.dataIn['title'];
      this.map = true;
    }
    this.filter = this.dataIn['filters'];
    this.left = this.dataIn['left'] ? this.dataIn['left'] : false;
    this.hotspotImage = this.ApiService.apiURL + this.dataIn.hotspotImage.url;
    console.log(this.dataIn)
    this.video = this.dataIn['video'];
  }

}
