<div class="background">
<div class="logo">
<img src="{{logoImage}}">
<span>Operations and Maintenence Guides</span>
</div>
<div class="menu-container">
<div (click)="navigateMap(map.title)" *ngFor="let map of data.maps" class="menu">
<img src="{{apiUrl + map.mapImage.url}}">
<span>{{map.title}}</span>
</div>
</div>
</div>
