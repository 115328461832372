import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { DrilldownService } from '../drilldown.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  searchText: string;
  hotspots: any[];

  searchToggle: boolean = false;

  constructor(public drilldownService: DrilldownService, private dashboard: DashboardComponent, private router: Router) { }

  ngOnInit(): void {}

  boxDisplayCtrl(arrayIn:any): void {
    if(arrayIn['pinTitle']){
      this.router.navigate(['/detail', arrayIn['route']]);
    }else{
      this.dashboard.resetMap(arrayIn['mapIdNumber']);
      this.searchToggle = false;
      this.searchText = "";
    }
  }

}
