import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HotspotComponent } from './hotspot/hotspot.component';
import { InfocardComponent } from './infocard/infocard.component';
import { EmbeddingPipe } from './embedding.pipe';
import { VideoPlayerComponent } from './video-player/video-player.component';
import { LandingComponent } from './landing/landing.component';
import { DrilldownComponent } from './drilldown/drilldown.component';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { MenuComponent } from './menu/menu.component';
import { FilterPipe } from './filter.pipe';
import { HotspotMenuComponent } from './hotspot-menu/hotspot-menu.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ErrorComponent } from './error/error.component';
// import { SpinnerCircularFixedModule } from './spinners-angular/spinner-circular-fixed';
import { LoadingComponent } from './loading/loading.component';
import * as $ from 'jquery';



@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    HotspotComponent,
    InfocardComponent,
    EmbeddingPipe,
    VideoPlayerComponent,
    LandingComponent,
    DrilldownComponent,
    MenuComponent,
    FilterPipe,
    HotspotMenuComponent,
    PrivacyComponent,
    ErrorComponent,
    LoadingComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
