import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { DrilldownService } from '../drilldown.service';

@Component({
  selector: 'app-hotspot-menu',
  templateUrl: './hotspot-menu.component.html',
  styleUrls: ['./hotspot-menu.component.scss']
})
export class HotspotMenuComponent implements OnInit {
  @Input() dataIn: string[];

  hotspots = [];

  constructor(private drilldownService: DrilldownService, private dashboard: DashboardComponent,
    private router: Router) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.dataIn.forEach(element => {
        this.hotspots.push(this.drilldownService.getHotspots().filter(val => val['pinTitle'] == element['pinTitle']));
      });
    }, 1000)
  }

  boxDisplayCtrl(pin){
    this.router.navigate(['/detail', pin[0]['route']]);
  }
}
