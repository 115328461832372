<div class="search-container">
<div class="search-field">
<div (click)="searchToggle = !searchToggle" class="search-icon">
<i class="fas fa-search"></i>
</div>
<input (ngModelChange)="searchToggle = true" [(ngModel)]="searchText" placeholder="Type to search pins..." type="text">
</div>
<div [hidden]="!searchToggle" class="results">
<div *ngFor="let hotspot of drilldownService.getHotspots() | appFilter: searchText" class="result">
<app-hotspot (click)="boxDisplayCtrl(hotspot)" [dataIn]="hotspot"></app-hotspot>
</div>
</div>
</div>
