<div [hidden]="!popupVideoPlay" class="popup-play-container">
<iframe [src]=" popupVideoURL | embedding" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" allowfullscreen="true" class="video" frameborder="0"></iframe>
<i (click)="playPopupVideo()" class="fas fa-times video-player-cross"></i>
</div>
<div class="container">
<!-- /Landing page Map -->
<div class="logo">
<img src="{{boxDataLogo}}">
</div>
<h2>{{boxDataSubtitle}}</h2>
<!-- /%h2 Please select one -->
<div class="map-container">
<div [ngStyle]="{&#39;background-image&#39;: &#39;url(&#39; + mapBg + &#39;)&#39;}" class="map">
<iframe *ngIf="mapVid != &quot;null?background=1&quot;" [src]=" mapVid | embedding" allow="autoplay; fullscreen" allowfullscreen="" class="map-vid-container" frameborder="0"></iframe>
<!-- /Hot spots -->
<div class="hotspot-container">
<app-hotspot (click)="boxDisplayCtrl(pin)" *ngFor="let pin of hotspots" [dataIn]="pin" [hidden]="hotspotDisplay(pin)" [ngClass]="{&quot;display-none&quot;: pin[&quot;menuHotspot&quot;]}" [ngStyle]="{&quot;left&quot;: pin.pinPositionX + &quot;%&quot;, &quot;top&quot;: pin.pinPositionY + &quot;%&quot;}" class="hotspot-style"></app-hotspot>
</div>
</div>
</div>
<!-- /Into Box -->
<h2 class="select">Please select a product</h2>
<div [hidden]="!boxLoaderDisplay" class="info-loader">
<div class="box-loader"></div>
</div>
<!-- /%app-drilldown{'[hidden]' => "!getDrillDownOpen()"} -->
<!-- .footer -->
<!-- %img.logo.unselectable.desktop{src: '{{boxDataLogo}}'} -->
<!-- .filter.mobile-filter -->
</div>
<!-- /Mobile swipe reminder -->
<div [hidden]="!showTutorial" class="mobile-tutorial-container">
<svg data-name="Swipe horizontal 1" id="Swipe-horizontal_1" viewbox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
<path class="hand-x" d="M139.93,113.56l-41.12-6.93V76.08a9.25,9.25,0,0,0-9.25-9.25h0a9.25,9.25,0,0,0-9.25,9.25v57.36L71,122.65c-3.61-3.61-8.44-3.89-13.08,0,0,0-7.24,5.84-3.83,9.25l34,34h42.63a9.25,9.25,0,0,0,9.07-7.43l6.82-34.09A9.28,9.28,0,0,0,139.93,113.56Z"></path>
<g class="swipe-horizontal">
<path class="line-horizontal" d="M70.85,42c19.69-12.46,37,0,37,0"></path>
<polyline class="arrow-left" points="76.6 46.01 68.37 43.43 68.38 43.41 70.96 35.18"></polyline>
<polyline class="arrow-right" points="100.21 44.66 108.43 42.08 108.43 42.06 105.85 33.84"></polyline>
</g>
</svg>
<span class="v2-type-sm-b tutorial-hint">Swipe Left / Right to view the map</span>
<div (click)="showTutorial = false" class="tutorial-btn hyper-link hover-reaction disable-select">
<span>Got it</span>
</div>
</div>
