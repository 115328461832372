import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {

  closed: boolean = false;

  constructor() { }

  ngOnInit(): void {
    var self = this;
    window['_paq'].push([function(){
      if(this.isUserOptedOut()){
        self.closed = false;
      }else{
        self.closed = true;
      }
    }])
  }

  consent(){
    this.closed = true;
    let _paq = window['_paq'];
    _paq.push(['rememberConsentGiven']);
  }

}
