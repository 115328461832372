// import { parseI18nMeta } from '@angular/compiler/src/render3/view/i18n/meta';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../api.service';
import { DrilldownService } from '../drilldown.service';
import { VimeoService } from '../service/vimeo.service';
import * as $ from 'jquery';
declare var $: any;

@Component({
  selector: 'app-drilldown',
  templateUrl: './drilldown.component.html',
  styleUrls: ['./drilldown.component.scss']
})
export class DrilldownComponent implements OnInit {

  @Input() dataIn: any;

  contactFirstName      = null;
  contactSurname        = null;
  contactEmail          = null;
  contactCompany        = null;
  contactMessage        = null;
  contactPhone          = null;
  contactInterest       = [false,false,false,false];
  contactGDPR           = false;

  boxData               = this.drilldownService.getBoxData();
  boxDataTitle          = this.drilldownService.getBoxDataTitle();
  boxDataDescription    = this.drilldownService.getBoxDataDescription();
  boxDataPDF            = this.drilldownService.getBoxDataPDF();
  boxDataInterest       = this.drilldownService.getBoxDataInterest();
  boxDataImage          = this.drilldownService.getBoxDataImage();
  boxDataDisplay        = 'intro';
  boxDataVideo          = this.drilldownService.getBoxDataVideo();
  boxDataVideoURL       = this.drilldownService.getBoxDataVideoUrl();
  boxDataSubtitle       = this.drilldownService.getBoxDataSubtitle();
  boxDataBackdrop       = this.drilldownService.getBoxDataBackdrop();
  apiURL                = this.apiService.getAPIURL();

  boxContentDisplay     = false;

  showFullscreenPic     = false;
  currentFullscreenPic  = null;

  GDPRdisplayStatus     = false;

  recaptchaVerified     = false;

  mobileMenuDisplay     = false;

  biggerImageMobile     = false;

  routeName             = "";

  fullscreen360         = true;

  previousTab           = null;

  procedures            = [];

  currentProdcedure = {videoURL: null, procedureId: null, title: null, id: null};

  procedureToggle: boolean = true;
  menuToggle: boolean = false;
  overviewToggle: boolean = true;

  constructor(public drilldownService: DrilldownService,
    private apiService: ApiService, private route: ActivatedRoute,
    private router: Router, public vimeo: VimeoService) { }

  ngOnInit(): void {
    this.route.params.subscribe(async params => {
      this.drilldownService.setMapId("");
      if(!params['name']){
        this.router.navigate(['/dashboard/main']);
      }
      this.routeName = params['name'];
      if(this.route.snapshot.queryParamMap.get('map')){
        this.drilldownService.setMapId(this.route.snapshot.queryParamMap.get('map'));
      }
      await this.drilldownService.getDrillDownData(this.routeName);

      this.drilldownService.drilldownOpen = true;
    });
  }

  closeBox(){
    this.drilldownService.drilldownOpen = false;
    this.drilldownService.contactOpen = false;
    this.drilldownService.setBoxDataBackdrop(null);
    this.drilldownService.boxDataDisplay  = "intro";
    this.drilldownService.emptyBoxDataBackgroundVideo();
    this.router.navigate(['/dashboard/' + this.drilldownService.getMapId()]);
  }

  boxContentDisplayCtrl(sectionIn:string, video?): void {
    let previous = this.drilldownService.getBoxDataDisplay();
    this.drilldownService.boxDataVideoURL       = ""; //reset video player
    if(sectionIn == 'video'){
      if(video != null){
        this.vimeo.loading = true;
        this.currentProdcedure = video;
        this.vimeo.nearest = null;
        this.vimeo.percentage = 0;
        this.drilldownService.setBoxDataVideoUrl(video['videoURL']);

        //Set Player
        this.vimeo.setVideo(video['videoURL']);
        this.getVideoProcedures(video.procedureId);
      }
    }else if(sectionIn == "contact"){
      this.previousTab = previous;
    }else{
      this.drilldownService.setBoxDataVideoUrl(null);
      this.currentProdcedure = {videoURL: null, procedureId: null, title: null, id: null};
    }
    this.drilldownService.setBoxDataDisplay(sectionIn);
    this.clearOverviewInfo();
    this.menuToggle = false;
  }

  getVideoProcedures(procedureId: string){
    this.apiService.getVideoProcedures(procedureId).subscribe((procedures: any) => {
      let proc = procedures.procedure;
      this.vimeo.timeStamps = proc;
    })
  }

  showFullscreenPicture(pictureURL: string): void{
    this.showFullscreenPic = !this.showFullscreenPic;
    this.currentFullscreenPic = pictureURL;
  }

  boxDocumentLauncher(arrayIn:string): void {
    window.open(this.apiURL + arrayIn['file'][0]['url'],'_blank');
  }

  openPdf(url){
    if(this.isMobile()){
      this.openLink(url);
    }else{
      this.drilldownService.setBoxDataSelectedPDF(url);
    }
  }

  isMobile(){
    try{ document.createEvent("TouchEvent"); return true; }
    catch(e){ return false; }
  }

  openLink(link){
    window.open(link, '_blank')
  }

  gdprDisplay(): void {
    this.GDPRdisplayStatus = !this.GDPRdisplayStatus;
  }

  gdprConsent(): void {
    this.contactGDPR = !this.contactGDPR;
  }


  //For the recaptcha
  resolved(capchtaResponse: string): void{
    this.recaptchaVerified = !this.recaptchaVerified;
  }

  canSubmit(): boolean{
    if(this.contactGDPR){//&& this.recaptchaVerified){
      return false;
    }
    return true;
  }

  nextVideo(){
    if(this.drilldownService.getBoxDataVideo()){
      if(this.drilldownService.getBoxDataVideo().length > 1){
        let procedureId = this.currentProdcedure.procedureId;
        console.log(procedureId + 1);
        let video = this.drilldownService.getBoxDataVideo().find(x => x.procedureId == Number(procedureId) + 1);
        console.log(video)
        if(video){
          this.boxContentDisplayCtrl("video", video);
        }else{
          video = this.drilldownService.getBoxDataVideo().find(x => x.procedureId == 1);
          this.boxContentDisplayCtrl("video", video);
        }
      }
    }
  }

  showOverviewInfo(pin){
    this.drilldownService.boxDataOverviewTitle = pin.title;
    this.drilldownService.boxDataOverviewDescription = pin.description;
    this.drilldownService.boxDataOverviewImage = pin.image.url;
    this.drilldownService.boxDataOverviewLink = pin.link
  }

  clearOverviewInfo() {
    this.drilldownService.boxDataOverviewTitle = null;
    this.drilldownService.boxDataOverviewDescription = null;
    this.drilldownService.boxDataOverviewImage = null;
    this.drilldownService.boxDataOverviewLink = null;
  }

  innerLogoDisplay(filterIn:string): boolean {
    if(this.drilldownService.getBoxData()) {
      if(this.drilldownService.getBoxData()['filters']){
        if (this.drilldownService.getBoxData()['filters'].indexOf(filterIn) >= 0) {
          return false
        } else {
          return true
        }
      }
    } else {
      return true
    }
  }

  playVideo(videoURL: string): void{
    this.drilldownService.setBoxDataVideoUrl(videoURL);
  }

  getBoxDataDisplay(){
    return this.drilldownService.getBoxDataDisplay();
  }

  getPlaying(video){
    if(this.drilldownService.getBoxDataVideoUrl() == video["videoURL"]){
      return video['title'] + " playing...";
    }else{
      return video['title']
    }
  }

  getOpened(sheet){
    if(this.drilldownService.getCurrentPdfUrl() == sheet['file'][0]['url']){
      return sheet['title'] + " open...";
    }else{
      return sheet['title']
    }
  }

  openFullscreen360(){
    $('#mySpriteSpin').spritespin('api').requestFullscreen();
  }

  closeFullscreen360(){
    this.fullscreen360 = true;
  }

  openExternalLink(){
    window.open(this.drilldownService.getBoxDataExternal(), "_blank");
  }

  launchContact() {
    window.open('https://www.se.com/uk/en/work/support/customer-care/contact-schneider-electric.jsp', '_blank').focus();
  }


  checkLastItem(arrayIn, itemIn) {
    if (itemIn == arrayIn.at(-1)){
      return true;
    }
  }

}
