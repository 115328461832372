import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Player from "@vimeo/player/dist/player.js";
import { DrilldownService } from '../drilldown.service';

@Injectable({
  providedIn: 'root'
})
export class VimeoService {
  interval: NodeJS.Timeout;

  constructor(private http: HttpClient, private drilldownService: DrilldownService) { }

  private accessToken = '17d5aadc7d8c1ab773fe871a59f83513';

  percentage = 0;

  currentVideo: Player = null;
  videoLink = null;
  videoId = null;
  nearest = null;

  timeStamps: any = [];

  paused: boolean = false;
  ended: boolean = false;

  manualPause: boolean = false;

  loading: boolean = false;

  autoplay: boolean = true;


  setVideo(videoLink: string){
    this.videoLink = videoLink;
    this.videoId =  this.videoLink.substr(31, (this.videoLink.length-1));
    setTimeout(() => {
      this.playVideo();
    },500);
  }

  playVideo(){
    this.loading = true;
    if(this.currentVideo){
      this.currentVideo.destroy();
    }
    this.currentVideo = null;
    let self = this;
    this.manualPause = false;
    this.ended = false;
    var options = {
      url: this.videoLink,
      loop: false,
      autoplay: false,
      background: true,
      responsive: true,
      muted: true
    }

    this.currentVideo = new Player('video-player-container', options);

    this.currentVideo.ready().then(() => {
      self.pause();
      this.currentVideo.on('timeupdate', function(data){
        if(!self.ended){
          self.percentage = data.percent*100;
        }else{
          self.percentage = 100;
        }
      });
      this.currentVideo.on('cuepoint', function(data){
        //self.checkTimestamps(data);
      });
      setTimeout(() => {
        this.loading = false;
        self.play();
      }, 2200)
      self.intervalTimestampCheck();
    })
    this.currentVideo.getCuePoints().then(cuePoints => {
      if(cuePoints.length == 0){
        this.addCuePoint();
      }
    })

    //Slow down playback speed
    this.currentVideo.setPlaybackRate(0.7);
  }

  checkTimestamps(data){
    let stamp = {time:  data.time, step: data.data.step, description: data.data.description, id: data.id};
    console.log(stamp)
    this.drilldownService.setCurrentProcedure(stamp);
    let timeout = Number(data.data.timeout);
    this.currentVideo.pause();
    this.paused = true;
    if(Number(data.data.timeout) != -1){
      console.log(this.autoplay);
      if(this.autoplay){
        setTimeout(() => {
          if(!this.manualPause){
            this.currentVideo.play();
            this.paused = false;
          }
        }, timeout);
      }
    }else{
      this.ended = true;
      this.percentage = 100;
      this.currentVideo.pause();
    }
  }

  intervalTimestampCheck(){
    let self = this;
    // console.log(this.timeStamps);
    this.interval = setInterval(() => {
      this.currentVideo.getCurrentTime().then(function(seconds){

          self.timeStamps.forEach(stamp => {
            let currentStamp = Number(stamp.time);
            if(currentStamp - (12.5/1000) <= seconds && currentStamp +  (12.5/1000) >= seconds && self.nearest !== currentStamp){
              self.nearest = currentStamp; //set temp storage

              let stampDrilldown = {time:  stamp.time, step: stamp.step, description: stamp.description, id: stamp.id, stepId: stamp.stepId};
              self.drilldownService.setCurrentProcedure(stampDrilldown);

              if(!self.autoplay) {
                self.setTime(currentStamp);
              }


              if(Number(stamp.timeout) != -1){
                let manPause = self.manualPause;
                self.pause();
                self.manualPause = manPause;
                if(self.autoplay){
                  console.log(stamp.timeout);
                  setTimeout(() => {
                    console.log("here");
                    if(!self.manualPause){
                      if(self.autoplay){
                        self.play();
                        self.paused = false;
                      }
                    }
                  }, stamp.timeout);
                }

              }else{
                if(!self.autoplay){
                  self.ended = true;
                  self.percentage = 100;
                  self.pause();
                }
              }
            }
          })

      })
    }, 20)
  }

  play(){
    this.currentVideo.play();
    this.paused = false;
    this.manualPause = false;
    this.ended = false;
    this.intervalTimestampCheck();
  }

  pause(){
    this.currentVideo.pause();
    this.paused = true;
    this.manualPause = true;
    clearInterval(this.interval);
  }

  setTime(currentStamp: number){
    this.currentVideo.setCurrentTime(currentStamp);
    this.currentVideo.pause();
    this.paused = true;
    clearInterval(this.interval);
  }

  addCuePoint(){
    let self = this;
    this.timeStamps.forEach(stamp => {
      console.log(stamp.timeout);
      self.currentVideo.addCuePoint(stamp.time, {
        "step": stamp.step,
        "description": stamp.description,
        "timeout": stamp.timeout,
      }).then(id => {
      });
    });
  }

  replay(){
    this.currentVideo.setCurrentTime(0);
    this.play();
    this.ended = false;
  }

  replayChapter(){
    console.log(this.drilldownService.getCurrentProcedure().time);
    this.currentVideo.setCurrentTime(this.drilldownService.getCurrentProcedure().time).then(() => {
      this.ended = false;
      this.play();
    })
  }

  async changeTimestamp(direction: number){
    this.nearest = null; //reset past stamp storage
    let newPoint;
    let current = this.timeStamps.find(x => x.id == this.drilldownService.getCurrentProcedure().id);
    this.manualPause = false; //cancel manual Pause

    let max = await this.findMaxStepId();

    //Prevent -1 error
    // if (current == -1) {
    //   current = 0;
    // }

    if((Number(current.stepId) + direction) > max){
      newPoint = this.timeStamps.find(x => Number(x.stepId) == 0);
    }else if(Number(current.stepId) + direction < 0){
      //newPoint = this.timeStamps.find(x => Number(x.stepId) == max);
    }else{
      newPoint = this.timeStamps.find(x => Number(x.stepId) == (Number(current.stepId) + direction));
    }

    if(newPoint){
      let stamp = {time:  newPoint.time, step: newPoint.step, description: newPoint.description, id: newPoint.id, stepId: newPoint.stepId};
      console.log(stamp);
      this.drilldownService.setCurrentProcedure(stamp);

      this.currentVideo.setCurrentTime(newPoint.time).then(() => {
        // this.pause();
        this.ended = false;
        this.play();
      })
    }
  }

  async findMaxStepId(){
    let biggest = 0;
    for(let i = 0; i < this.timeStamps.length; i++){
      if(Number(this.timeStamps[i].stepId) > biggest){
        biggest = Number(this.timeStamps[i].stepId);
      }
    }
    return biggest;
  }

  playOrPause(){
    if(this.paused){
      this.play();
    }else{
      this.pause();
    }
  }
}
