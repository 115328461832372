import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
//import { async } from 'rxjs/internal/scheduler/async';


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }
  token: string = '';
  apiURL: string = 'https://cms02.the-podium.com';
  collectionPath: string = '/podium-schneiders' ;
  authPath: string = '/api/cockpit/authUser';
  assetPath: string = '/api/cockpit/assets';


  getAPIURL() {
    return this.apiURL;
  }

  getVideo(message: string){
    return message + '.mp4';
  }

  getMapPin(){
    let pinApiURL = this.apiURL + this.collectionPath;
    let obs = this.http.get(pinApiURL);
    return obs;
  }

  getMapWithID(ID:string){
    let pinApiURL = this.apiURL + this.collectionPath + '/' + ID;
    let obs = this.http.get(pinApiURL);
    return obs;
  }

  async getMaps(){
    let url = this.apiURL + this.collectionPath + '/'
    return this.http.get(url);
  }

  getVideoProcedures(id: string){
    return this.http.get(this.apiURL + this.collectionPath + "-procedures/" + id);
  }

  getDrilldown(){
    let pinApiURL = this.apiURL + this.collectionPath +  'Drilldown/?token=' + this.token;
    let obs = this.http.get(pinApiURL);
    return obs;
  }

  getContentItems() {
    let pinApiURL = this.apiURL + this.collectionPath +  'ContentItems/?token=' + this.token;
    let obs = this.http.get(pinApiURL);
    return obs;
  }

  getAsset(){
    let pinApiURL = this.apiURL + this.assetPath + '?token=' + this.token;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };

    let obs = this.http.get(pinApiURL);
    return obs;
  }

  getPoster(StringIn: string) {
    let URL = this.apiURL + '/' + StringIn;
    return URL.toString();
  }

  getPDF(StringIn: string) {
    let URL = this.apiURL + '/' + StringIn;
    return URL.toString();
  }

  async getDrilldownRoute(route, map){
    return this.http.get(this.apiURL + this.collectionPath + '/').toPromise();
  }


  async getGlobalDrilldowns(){
    return this.http.get(this.apiURL + this.collectionPath + "-hotspots/").toPromise();
  }

  getAuth() {
    fetch(this.apiURL + this.authPath + '?token=' + this.token, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            user: 'cfok',
            password: 'cfok'
        })
    })
    .then(user => user.json())
    .then(user => console.log(user));
  }

  async authenticateUser(email: string, password: string): Promise<boolean>{
    let authenticated = false;

    await axios.post(this.apiURL + '/auth/local', {identifier: email, password: password}).then(response => {
      localStorage.setItem('access_token', response.data.jwt);
      authenticated = true;
    }).catch(() => {
      authenticated = false;
    });
    return authenticated;
  }

  async forgotPassword(email): Promise<boolean>{
    let success = false;
    await axios.post(this.apiURL + '/auth/forgot-password', {email: email}).then(response => {
      if(response.status == 200){
        success = true;
      }
    }).catch((e) => {console.log(e); success = false})
    return success;
  }

  isAuthenticated(){
    if(localStorage.getItem('access_token')){
      let jwtDecode = jwt_decode(localStorage.getItem('access_token'));
      if(jwtDecode['exp']*1000 > new Date().getTime()){
        return true;
      }
    }
    return false;
  }
}
