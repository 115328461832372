import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { DashboardComponent }   from './dashboard/dashboard.component';
import { DrilldownComponent } from './drilldown/drilldown.component';
import { ErrorComponent } from './error/error.component';
import { LandingComponent } from './landing/landing.component';

const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full'},//component: LandingComponent},
  { path: 'dashboard', component: DashboardComponent},//, canActivate: [AuthGuard] },
  { path: 'dashboard/:id', component: DashboardComponent},//, canActivate: [AuthGuard] },
  { path: 'detail/:name', component: DrilldownComponent},//, canActivate: [AuthGuard]},
  { path: 'detail', redirectTo: '/dashboard'},
  { path: 'error', component: ErrorComponent},
  { path: '**', redirectTo: '/error'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
