import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../api.service';
import { DrilldownService } from '../drilldown.service';
import { HotspotService } from '../hotspot.service';
import { catchError, map} from 'rxjs/operators';
import { of } from 'rxjs';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  contactFirstName      = null;
  contactSurname        = null;
  contactEmail          = null;
  contactCompany        = null;
  contactMessage        = null;
  contactPhone          = null;
  contactInterest       = [false,false,false,false];
  contactGDPR           = false;

  //recaptchaVerified     = false;

  mapData               = null;
  mapBg                 = null;
  mapVid                = null;
  hotspots              = null;
  hotspotFilter         = [];
  boxData               = null;
  boxDataTitle          = null;
  boxDataDescription    = null;
  boxDataPDF            = null;
  boxDataInterest       = null;
  boxDataImage          = './../assets/images/na-image.jpg';
  boxDataDisplay        = 'intro';
  boxDataVideo          = null;
  boxDataVideoURL       = null;
  boxDataSubtitle       = null;
  boxDataMaps           = null;
  boxDataMenuHotspots   = null;
  boxDataBackdrop       = null;

  boxDataLogo           = null;
  boxDataFilter         = null;

  subMenuShow           = [];

  boxDisplay            = this.DrillDownService.getBoxDisplay();
  boxLoaderDisplay      = false;
  boxContentDisplay     = true;
  mobileMenuDisplay     = false;
  mobileReminderDisplay = false;
  GDPRdisplayStatus     = false;
  showTutorial          = true;
  apiURL                = this.ApiService.getAPIURL();

  filterBulk            = true;
  filterPals            = true;
  filterTritec          = true;
  filterNorthern        = true;

  popupVideo            = null;
  popupVideoPlay        = false;
  popupVideoURL         = null;

  logoURL               = null;

  showFullscreenPic     = false;
  currentFullscreenPic  = null;
  mapId                 = "main";

  currentDrilldown      = null;
  contactOpen           = false;

  constructor(private ApiService: ApiService,
    private DrillDownService: DrilldownService,
    public hotspotService: HotspotService,
    private router: Router,
    private route: ActivatedRoute) { }

  async ngOnInit(){
    this.route.params.subscribe(async params => {
      if(!params['id']){
        this.router.navigate(['dashboard/main']);
      }else{
        this.mapId = await params['id'];
        await this.initMap(this.mapId);
      }
    })
  }

  async initMap(id){
    let failed = false;
    (await this.ApiService.getMaps()).pipe(catchError( err => {
      if(err.status === 404){
        failed = true;
        return of(true);
      }
    }))
      .subscribe((response: any) => {
        if(!failed){
          let maps = response;
          let map = maps.find(x => x.mapTitle == id);
          if(map != null){
            this.mapData = map;
            this.DrillDownService.setBoxDataInterest(this.mapData['interest']);
            if(this.mapData['mapBackgroundImage']){
              this.mapBg = this.apiURL + this.mapData['mapBackgroundImage']['url'];
            }
            this.mapVid = this.mapData['mapVideoURL'] + '?background=1';
            console.log(this.mapVid);
            this.boxDataTitle = this.mapData['mapTitle'];
            this.boxDataSubtitle = this.mapData['mapSubtitle'];
            if(this.mapData['logoImage']){
              this.boxDataLogo = this.apiURL + this.mapData['logoImage']['url'];
            }
            this.boxDataFilter = this.mapData['filters'];
            this.boxDataMaps = this.mapData['maps'];
            this.hotspotService.setFilters(this.boxDataFilter);
            //this.popupVideo = //put the imformation about the video here, normally from JSON;
            this.initHotspots(this.mapData['hotspot']);
            this.initSearchHotspots(this.mapData);
            //this.initSubMenuHotspots(this.mapData['hotspotMenu']);
          }else{
            this.router.navigate(['error']);
          }
        }else{
          this.router.navigate(['error']);
        }
      });
  }

  resetMap(id): void{
    this.router.navigate(['/dashboard', id]);
  }

  topLevel(){
    this.router.navigate(['dashboard/main']);
  }

/*   initSubMenuHotspots(arrayIn){
    this.boxDataMenuHotspots = arrayIn;
    arrayIn.forEach(element => {
      this.subMenuShow[element['id']] = true;
    });
  } */

  initSearchHotspots(arrayIn:object){
    let end = [];
    let hotspots = arrayIn['hotspot'];
    let maps = arrayIn['maps'];
    hotspots.forEach(element => {
      end.push(element);
    });
    maps.forEach(element => {
      end.push(element);
    });
    this.DrillDownService.setHotspots(end);
  }

  initHotspots(arrayIn:object): void {
    console.log(arrayIn);
    this.hotspots = arrayIn;
  }

  contactCtrl(): void {
    this.router.navigate(['/detail', "contact"], {queryParams: {"map": this.mapId}});
    /*
    this.DrillDownService.setBoxDataDisplay("contact");
    this.boxDataDisplay    = 'contact';
    this.boxDisplay         = true;
    this.boxContentDisplay  = true;
    this.DrillDownService.contactOpen = true;
    */
  }

  boxDisplayCtrl(arrayIn:any): void {
    if(arrayIn.video.length > 1){
      if(arrayIn['externalURL'] == "globalHotspot"){
        this.router.navigate(['/detail', arrayIn['route']], {queryParams: {"map": this.mapId}});
      }else{
        this.router.navigate(['/detail', arrayIn['route']]);
      }
    }

    /*
    console.log(arrayIn);
    this.DrillDownService.drilldownOpen = true;
    this.DrillDownService.setDrillDownData(arrayIn);
    this.boxDisplay = !this.boxDisplay;
    */
  }

  subMenuChange(id){
    this.subMenuShow[id] = !this.subMenuShow[id];
  }

  hotspotDisplay(arrayIn:object): boolean {

    let emptyArray = true;
    this.hotspotFilter.forEach(function(element) {
      if(element) {
        emptyArray = false;
      }
    })

    if(emptyArray) {
      return false;
    } else {
      if(arrayIn['filters'].indexOf(this.hotspotFilter[0]) >= 0) {
        //return false;
        return true;
      } else if(arrayIn['filters'].indexOf(this.hotspotFilter[1]) >= 0) {
        //return false;
        return true;
      } else if(arrayIn['filters'].indexOf(this.hotspotFilter[2]) >= 0) {
        //return false;
        return true;
      } else if(arrayIn['filters'].indexOf(this.hotspotFilter[3]) >= 0) {
        //return false;
        return true;
      } else {
        //return true;
        return false;
      }
    }
  }

  //Displaying the greyed filter
  filterDisplay(nameIn:string, indexIn:number): boolean {
    if(this.hotspotFilter[indexIn] === nameIn) {
      return true;
    } else {
      return false;
    }
  }

  //Puts the filter into the array
  filterHandler(filterIn:string, indexIn:number): void {
    if(!this.hotspotFilter[indexIn]) {
      this.hotspotFilter[indexIn] = filterIn;
    } else {
      this.hotspotFilter[indexIn] = null;
    }
  }

  innerLogoDisplay(filterIn:string): boolean {
    if(this.boxData) {
      if(this.boxData['filters']){
        if (this.boxData['filters'].indexOf(filterIn) >= 0) {
          return false
        } else {
          return true
        }
      }
    } else {
      return true
    }
  }

  boxContentDisplayCtrl(sectionIn:string): void {
    this.boxDataDisplay = sectionIn;
    if(sectionIn == 'video'){
      if(this.boxDataVideo.length >= 0){
        this.boxDataVideoURL = this.boxDataVideo[0]['videoURL'];
      }
    }else{
      this.boxDataVideoURL = null;
    }
  }

  boxDocumentLauncher(arrayIn:string): void {
    window.open(this.apiURL + arrayIn['file'][0]['url'],'_blank');
  }

  gdprDisplay(): void {
    this.GDPRdisplayStatus = !this.GDPRdisplayStatus;
  }

  contactFormHandler(): void {

  }

  gdprConsent(): void {
    this.contactGDPR = !this.contactGDPR;
  }

  /*
  //For the recaptcha
  resolved(capchtaResponse: string): void{
    this.recaptchaVerified = !this.recaptchaVerified;
  }
  */
  canSubmit(): boolean{
    if(this.contactGDPR){//&& this.recaptchaVerified){
      return false;
    }
    return true;
  }

  playPopupVideo(): void{
    this.popupVideoPlay = !this.popupVideoPlay;
    if(this.popupVideoPlay){
      this.popupVideoURL = this.popupVideo['videoURL'];
    }else{
      this.popupVideoURL = null;
    }
  }

  showFullscreenPicture(pictureURL: string): void{
    this.showFullscreenPic = !this.showFullscreenPic;
    this.currentFullscreenPic = pictureURL;
  }

  drillDownOpen(title: string): boolean{
    if(this.boxDisplay){
      if(title = this.currentDrilldown){
        return false;
      }else{
        return true;
      }
    }
    return true;
  }

  getDrillDownOpen(): boolean{
    this.boxDisplay = false;
    return this.DrillDownService.getDrilldownOpen();
  }

  getContactOpen(): boolean{
    this.boxDisplay = false;
    return this.DrillDownService.getContactOpen();
  }
}
