import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  title: string = "Podium Demo";
  subtitle: string = "Enter your password to experience the Podium.";

  data: any = [];

  logoImage: string = "";
  apiUrl = this.apiService.apiURL;

  constructor(private router: Router, private apiService: ApiService) { }

  ngOnInit(): void {
    this.apiService.getMapWithID("1").subscribe((res:any) => {
      this.data = res;
      this.logoImage = this.apiUrl + res.logoImage.url;
    })
  }

  navigateMap(title: string){
    this.router.navigate(['/dashboard', title]);
  }
}
