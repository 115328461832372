<div [hidden]="closed" class="privacy-container">
<p>This site uses Matomo to analyse traffic and help us to improve you user experience. It is processing your IP address and stores cookies on your browser for 13 months. Those data are only processed by us and our web hosting platform.</p>
<a href="" target="_blank">Learn more about our Privacy Policy</a>
<div class="btn-container">
<div (click)="consent()" class="btn">
<span>Accept</span>
</div>
<div (click)="closed = true" class="btn decline">
<span>Decline</span>
</div>
</div>
</div>
